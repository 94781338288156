import React, { useContext, useState } from 'react'

import { Context } from '../context'
import './Modal.css'

import bitcoinLogo from "../images/bitcoinLogo.png"
import qrLight from "../images/qr-light.png"
import qrDark from "../images/qr-dark.png"

function Modal() {
  const { toggleModal, theme } = useContext(Context)

  const [copy, setCopy] = useState('')

  const btcAddress = 'bc1qs3veh5mjn8l9pv4r6syy8pt9znndr6jg3k0jpg'
  const btcDisplay = 'bc1q....0jpg'

  const copyToClipboard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe)
      setCopy('Address Copied')
    } catch (err) {
      setCopy('Failed to Copy')
    }
  }

  return (
    <div className={`${theme}-theme modal--background`}>
      <div className='modal--container'>
      <div className='modal--close'>
        <button className={`${theme}-theme modal--close__button`}
                onClick={() => toggleModal(false)}
                aria-label="btc wallet address information">X
        </button>
      </div>
        <div className='modal--logo'>
          <img className='modal--logo__image'
               src={bitcoinLogo}
               alt='Bitcoin Logo' />
        </div>
        <div className='modal--address'>
          <button className={`${theme}-theme modal--address__button modal--button`}
                onClick={() => copyToClipboard(btcAddress)}
                aria-label="Copy btc address to clipboard">
                {copy === '' ? btcDisplay : copy}</button>
        </div>
        <div className='modal--qrcode'>
          <img className='modal--qrcode__image'
              src={theme === 'light' ? qrLight  : qrDark}
              alt='QR Code for Bitcoin Trivia BTC Address'
              aria-label="QR Code for btc wallet address" />
        </div>
        {/* <div className='modal--email'>
          <a href={`mailto:?subject=Bitcoin Trivia Address&body=Here is the BTC Address to help support Bitcoin Trivia: %0a%0a ${btcAddress}`}>
            <button className={`${theme}-theme modal--button`}>Email Address</button>
          </a>
        </div> */}
      </div>
    </div>
  )
}

export default Modal;