import { nanoid } from "nanoid" 

function reconstruct(data) {
    let reconstructData = new Array(data.length)
    for (let i = 0; i < data.length; i++) { // i loops over all questions
        
        let correctId = nanoid()
        let incorr0Id = nanoid()
        let incorr1Id = nanoid()
        let incorr2Id = nanoid()

        // =============== LEGACY INCORRECT ANSWER ARRAY =================
        // let incorrectAnswers = []
        // let incorrectIds = []
        // for (let j = 0; j < data[i].incorrect.length; j++) {
        //     incorrectAnswers.push(data[i].incorrect[j])
        //     incorrectIds.push(nanoid())
        // }

        // =============== CRAZY SHUFFLING ROUTINE =================
        // The answers need to be shuffled in reconstructData so that react state
        // doesn't shuffle the answers during each state change.
        // However, the answerIds need to be shuffled by the same indices,
        // so that the answers and answer id's stay in order.

        const x = [ 0, 1, 2, 3 ]; // index array
        const s = shuffle(x);
        const answers = [ data[i].correct, data[i].incorr0, data[i].incorr1, data[i].incorr2 ];
        const answerIds = [ correctId, incorr0Id, incorr1Id, incorr2Id ]; 
        const shuffledAnswers = [ answers[s[0]], answers[s[1]], answers[s[2]], answers[s[3]] ]; 
        const shuffledAnswerIds = [ answerIds[s[0]], answerIds[s[1]], answerIds[s[2]], answerIds[s[3]] ];
        
        // ======== CREATE THE DATA OBJECT FOR EACH QUESTION =================
        reconstructData[i] = {
            key: nanoid(),
            id: data[i].id,
            question: data[i].question,
            correct: data[i].correct,
            correctId: correctId,
            selected: null,
            answers: shuffledAnswers,
            answerIds: shuffledAnswerIds,
        }
    }

    // =============== CHOOSE THREE RANDOM QUESTIONS =================
    let indices = []
    while(indices.length < 3) {
        let q = Math.floor(Math.random() * data.length)
        if (indices.indexOf(q) === -1) {
            indices.push(q)
        }
    }

    let returnData = [
        reconstructData[indices[0]],
        reconstructData[indices[1]],
        reconstructData[indices[2]]
    ] //console.log('returnData: ', returnData)

    return returnData

    function shuffle(arr) {
        return arr.sort((a, b, c, d) => 0.5 - Math.random())
      }
}

export default reconstruct