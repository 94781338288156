import React, {useContext} from 'react'
import Start from './components/Start'
import Quiz from './components/Quiz'

import { Context } from './context'
import './App.css';

import bgLight from './images/bg-light-rgb.png'
import bgDark from './images/bg-dark-rgb.png'

function App() {
  const { start, theme } = useContext(Context)

  const bgStyle = {
    backgroundImage: `url(${theme === 'light' ? bgLight : bgDark})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }

  return (
    <div 
      className='app'
      style={bgStyle}>

    {start ?
      <Quiz />
      :
      <Start />
    }
    </div>
  );
}

export default App;