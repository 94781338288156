import React, { useEffect, useState, useContext } from 'react'

import { Context } from '../context'

function Countdown() {
  const { LOCAL_STORAGE_KEY,
    toggleStart,
    theme,
    lastVisit, 
    setLastVisit,
    check } = useContext(Context)

  const [timeRemaining, setTimeRemaining] = useState(countdown());

  useEffect(() => {
    const localTimeStamp = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    if (localTimeStamp) {
      setLastVisit(localTimeStamp)
    } // eslint-disable-next-line 
  },[check])
  
  // ====================== COUNTDOWN FUNCTION =========================
  
  function countdown() {
    const diff = +new Date(lastVisit) - +new Date();
    let delta = {};

    if (diff > 0) {
      delta = {
        hours: Math.floor(diff / (1000 * 60 * 60)),
        minutes: Math.floor((diff / 1000 / 60) % 60),
        seconds: Math.floor((diff / 1000) % 60)
      };
    }
    return delta;
  };

  useEffect(() => {
    setTimeout(() => {
        setTimeRemaining(countdown())
    }, 1000);
  });

  // ======================== RENDER =========================

  return (
    <div className="App">
      {timeRemaining.minutes || timeRemaining.seconds ? (
        <button
          className={`${theme}-theme start--button first--button disabled`}
          disabled={true}
          aria-label='Start Quiz disabled for ten minutes'>
          <span>{timeRemaining.minutes}</span>
          <span>{timeRemaining.seconds < 10 ? ':0' : ':'}</span>
          <span>{timeRemaining.seconds}</span>
        </button>
      ) : (
        <button
          className={`${theme}-theme start--button`}
          onClick={toggleStart}
          aria-label='Start Quiz'>
          Get it on ⚡</button>
      )}
    </div>
  );
}

export default Countdown;