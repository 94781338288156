import React, {useState, useEffect, useContext} from 'react'
import Header from './Header'
import Footer from './Footer'
import Question from './Question'
import Modal from './Modal'
import Terms from './Terms'
import Privacy from './Privacy'
import Confetti from 'react-confetti'
import { useWindowSize } from '@react-hook/window-size' // for Confetti

import { Context } from "../context"
import reconstruct from "../reconstruct"
import './Quiz.css'

function Quiz() {
  const { LOCAL_STORAGE_KEY,
    start,
    toggleStart,
    theme,
    modal,
    toggleModal,
    setLastVisit,
    check,
    toggleCheck,
    terms,
    privacy } = useContext(Context)

  const [ quizData, setQuizData ] = useState([])
  const [ count, setCount ] = useState(0)
  const [ disabled, setDisabled ] = useState(true)
  const [ champ, setChamp ] = useState(false)
  const [ quizError, setQuizError ] = useState(false)
  
  const { width, height } = useWindowSize() // for Confetti

  // ======================== LOAD DATA ========================
  const url = 'https://us-east-1.aws.data.mongodb-api.com/app/nakamoto-scdeh/endpoint/questions?secret=satoshi'

  useEffect(() => {
    if (start) {
      fetch(url)
      .then(res => res.json())
      .then(data => {
        setQuizData(reconstruct(data))
      })
    }
  }, [start])

  // =========================== MAIN ==========================
  // useEffect(() => {
  //   const allSelected = quizData.every(data => data.selected)
  // }, [quizData])
  
  let questionElements = []
  questionElements = quizData.map(quiz => (
    <Question 
      key={quiz.key}
      id={quiz.id}
      question={quiz.question}
      correct={quiz.correct}
      correctId={quiz.correctId}
      answers={quiz.answers}
      answerIds={quiz.answerIds}
      selected={quiz.selected}
      holdAnswer={holdAnswer}
      check={check}/>
  ))

  // ======================== FUNCTIONS =========================
  function holdAnswer(answerId) {
    setQuizData(oldQuizData => oldQuizData.map(data => {
      return data.answerIds.includes(answerId) ?
      {...data,
      selected: answerId} :
      data
    }))
    counter()
  }

  function counter() {
    let selectedValues = quizData.map(data => {
      return data.selected
    })
    let newCount = selectedValues.filter(value => value !== null).length + 1
    setCount(newCount)
    changeDisabled(newCount)
  }

  function changeDisabled(count) {
    if (count >= 3) {
      setDisabled(false)
    }
  }

  function handleCheck(count, start){
    toggleCheck()
    let correctAnswers = 0
    for (let i = 0; i < quizData.length; i++) {
        if (quizData[i].correctId === quizData[i].selected) {
            correctAnswers++
        }
    }

    if (correctAnswers === 3) {
        setChamp(true)
    }
    
    let checkAnswers = new Date()
    //checkAnswers.setHours(checkAnswers.getHours() + 21)
    checkAnswers.setMinutes(checkAnswers.getMinutes() + 10)
    //checkAnswers.setSeconds(checkAnswers.getSeconds() + 10)
    setLastVisit(checkAnswers)
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(checkAnswers))
 }
 
 function playAgain() {
    toggleStart()
    setQuizData([])
    setCount(0)
    toggleCheck()
    setDisabled(true)
    setChamp(false)
 }

// ======================== BUTTON STYLES =========================
let buttonStyle = {}

function checkStyles(count) {
  if (count >= 3) {
      buttonStyle = {
        opacity: 1
      }
    }

  return buttonStyle
}
checkStyles(count)

// ============================ RENDER =============================
  return (
    <>{champ &&
      <Confetti width={width} 
                height={height}
                numberOfPieces={200}
                colors={['#ff9800','#888888','#444444','cornflowerblue','pink']}/>}
      <div className={`${theme}-theme main--background`}>
          {modal && <Modal />}
          {terms && <Terms />}
          {privacy && <Privacy />}
          <Header />  
          <div className='quiz--container'>
            {questionElements}
          <div/>
          <div className='quiz--buttons'>
            <button className={`${theme}-theme quiz--button ${disabled && 'disabled'} quiz--again`}
                    disabled={disabled}
                    style={buttonStyle}
                    onClick={check ? playAgain : handleCheck}
                    aria-label="check answers">
                    {check ? "Play Again 🎊" : "Check Answers 😬"}
            </button>
            <button className={`${theme}-theme quiz--button sendButtonOff ${check && 'sendButtonOn'}`}
                    onClick={() => toggleModal(true)}
                    aria-label="Send sats to Bitcoin Trivia">
                    Send Sats 🛰️
            </button>
          </div>
          </div>
          <Footer />
      </div></>
    )
  }

  export default Quiz