import React, {useContext} from "react"
import { Context } from "../context"

import { nanoid } from "nanoid"

function Answer(props) {
    const { theme } = useContext(Context)
    const { correctId, answer, answerId, selected, holdAnswer, check } = props

    let styles = {}

    function styler() {
        if (check && answerId === correctId && selected === correctId) {
            styles = {
                backgroundColor: "seagreen",
                color: "whitesmoke",
                pointerEvents: 'none'
            }

        } else if (check && answerId === selected && selected !== correctId) {
            styles = {
                backgroundColor: "firebrick",
                color: "whitesmoke",
                pointerEvents: 'none'
            }

        } else if (check && answerId !== selected) {
            styles = {
                opacity: 0.25,
                pointerEvents: 'none'
            }

        } else if (answerId === selected) {
            styles = {
                backgroundColor: "#ff9800"
            }

        } else {
            styles = {
                backgroundColor: ""
            }
        }
        return styles
    }

    styler()

    return (
        <button
            style={styles}
            className={check ? `${theme}-theme quiz--answer checked` : `${theme}-theme quiz--answer`}
            key={nanoid}
            onClick={() => holdAnswer(answerId)}
            disabled={check}
            aria-label={answer}>
            {answer}
        </button>
    )
}

export default Answer