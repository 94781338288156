import React, { useContext, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import * as yup from 'yup' // import all as yup
import ReCAPTCHA from 'react-google-recaptcha'

import './Form.css'

import Header from './Header'
import Footer from './Footer';
import './Header.css'
import './Footer.css'

import { Context } from '../context'

function Form() {
    const { toggleForm, theme } = useContext(Context)
    const [ submitted, setSubmitted ] = useState(false)
    const [ captcha, setCaptcha ] = useState(false)

    const handleRecaptcha = () => {
        setTimeout(() => {
            setCaptcha(true)
        }, 1500)
    }

    const schema = yup.object().shape({
        timestamp: yup.number().default(() => moment().unix()),
        email: yup.string().email().required("Please enter a valid email address."),
        question: yup.string().required("Please enter a well-researched question."),
        correct: yup.string().required("Please enter the correct answer."),
        incorr1: yup.string().required("Please enter an incorrect answer."),
        incorr2: yup.string().required("Please enter another incorrect answer."),
        incorr3: yup.string().required("Last incorrect answer. Make it good."),
        terms: yup.bool().oneOf([true], "You must agree to submit a question.").required()
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmit = (form) => {
        setSubmitted(true)
        const URL = "https://us-east-1.aws.data.mongodb-api.com/app/nakamoto-scdeh/endpoint/submissions"
        fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                timestamp: form.timestamp,
                email: form.email,
                question: form.question,
                correct: form.correct,
                incorr1: form.incorr1,
                incorr2: form.incorr2,
                incorr3: form.incorr3,
                terms: form.terms
            })
        })
        .then(() => {
            alert('Question Submitted')
        })
        .catch(error => {
            const message = `There was an error. It's not your fault. Please try again later. ${error}`
            alert(message)
        })
    }
   
  return (
    <div className={`${theme}-theme form--background`}>
        <Header />
        <>
        {captcha ?
        <div className="form--container">
            <div className="form--text">
                <p>While Bitcoin is busy boiling the oceans, you're busy contemplating
                the next epic Bitcoin Trivia question. Good on yah!</p><br />
                
                <p>While we're kidding about the whole 
                "boiling the oceans" bit, there are some boiler plate terms and conditions you must read
                and agree to before submitting a question. Ah, isn't centralization great?
                Please review the terms before clicking send.
                There's a link in the footer below. </p><br />
                
                <p>Ok, now that that's out of the way, let's see what you got.</p>
            </div>
            <div className="form--div">
                <form onSubmit={handleSubmit(onSubmit)}
                      aria-label="Submit a question form">
                    <div className="form--field">
                        <input className={`${theme}-theme form--input`} 
                                type="text" 
                                placeholder="Email Address"
                                aria-label="Email Address"
                                {...register("email")} />
                        <p className="form--error">{errors.email?.message}</p>
                    </div>

                    <div className="form--field">
                        <input className={`${theme}-theme form--input`} 
                                type="text"
                                placeholder="Question"
                                aria-label="Question"
                                {...register("question")} />
                        <p className="form--error">{errors.question?.message}</p>
                    </div>

                    <div className="form--field">
                        <input className={`${theme}-theme form--input`}
                                type="text" 
                                placeholder="Correct Answer"
                                aria-label="Correct Answer"
                                {...register("correct")} />
                        <p className="form--error">{errors.correct?.message}</p>
                    </div>

                    <div className="form--field">
                        <input className={`${theme}-theme form--input`}
                                type="text"
                                placeholder="Incorrect Answer"
                                aria-label="First incorrect answer"
                                {...register("incorr1")} />
                        <p className="form--error">{errors.incorr1?.message}</p>
                    </div>

                    <div className="form--field">
                        <input className={`${theme}-theme form--input`}
                                type="text"
                                placeholder="Another Incorrect Answer"
                                aria-label="Second incorrect answer"
                                {...register("incorr2")} />
                        <p className="form--error">{errors.incorr2?.message}</p>
                    </div>

                    <div className="form--field">
                        <input className={`${theme}-theme form--input`}
                                type="text"
                                placeholder="One more Incorrect Answer"
                                aria-label="Third incorrect answer"
                                {...register("incorr3")} />
                        <p className="form--error">{errors.incorr3?.message}</p>
                    </div>

                    <div className="form--terms">
                        <div className="form--terms__pair">
                            <input className={`${theme}-theme form--input form--terms__checkbox`}
                                    type="checkbox"
                                    aria-label="Agree with terms and conditions check box"
                                    {...register("terms")} />
                            <label htmlFor="terms">I agree to the Terms & Conditions</label>
                        </div>
                        <p className="form--error">{errors.terms?.message}</p>
                    </div>
                    <div className="form--footer">
                        <div className="form--submit">
                            <input className={`${theme}-theme form--button ${submitted && 'disabled'}`}
                                    type="submit"
                                    aria-label="Submit the form"
                                    value={`${!submitted ? 'Full Send 🚀' : 'Sent 🥳'}`} />
                        </div>
                        <div className="form--close">
                            <button className={`${theme}-theme form--button`}
                                    aria-label="Close the form"
                                    onClick={() => toggleForm(false)}>
                                    Close Form 👻
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div> : 
        <div className='form--recaptcha'>
            <ReCAPTCHA
                sitekey="6Lf2t0klAAAAAKPXBQtSmnnmkPiisPUayWtRiqRJ"
                theme={theme}
                size={window.innerWidth < 400 ? "compact" : "normal"}
                onChange={handleRecaptcha}
                aria-label="Google Recaptcha"/>
        </div> }
        </>
    </div>
  )
}

export default Form