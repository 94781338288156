import React, {useState} from "react"

const Context = React.createContext()

function ContextProvider({children}) {
    const LOCAL_STORAGE_KEY = 'lastVisit'
    const [ start, setStart ] = useState(false)
    const [ theme, setTheme ] = useState('light')
    const [ modal, setModal ] = useState(false)
    const [ form, setForm ] = useState(false)
    const [ lastVisit, setLastVisit ] = useState('')
    const [ check, setCheck ] = useState(false)
    const [ terms, setTerms ] = useState(false)
    const [ privacy, setPrivacy ] = useState(false)

    function toggleStart() {
        setStart(prevStart => !prevStart)
    }

    function toggleTheme() {
        setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light')
    }

    function toggleModal() {
        setModal(prevModal => !prevModal)
    }

    function toggleForm() {
        setForm(prevForm => !prevForm)
    }

    function toggleCheck() {
        setCheck(prevCheck => !prevCheck)
    }

    function toggleTerms() {
        setTerms(prevTerms => !prevTerms)
    }

    function togglePrivacy() {
        setPrivacy(prevPrivacy => !prevPrivacy)
    }

    return (
        <Context.Provider value={{
            LOCAL_STORAGE_KEY,
            start,
            toggleStart,
            theme,
            toggleTheme,
            modal,
            toggleModal,
            form,
            toggleForm,
            lastVisit,
            setLastVisit,
            check,
            toggleCheck,
            terms,
            toggleTerms,
            privacy,
            togglePrivacy
        }}>
            {children}
        </Context.Provider>
    )
}

export { ContextProvider, Context }