import React, {useContext} from 'react'

import { Context } from "../context"
import './Header.css'

import bitcoinLogo from "../images/bitcoinLogo.png"
import mascotDark from "../images/diva-nerd-L.png"
import mascotLight from "../images/fiona-nerd-L.png"

function Header() {
  const { start, theme, toggleTheme, form } = useContext(Context)
  return (
    <div className='header--container'>
    <a className="header--logo"
       href="https://bitcoin.org/bitcoin.pdf"
       target="_blank"
       rel="noreferrer"
       aria-label="Link to Bitcoin Whitepaper">
    <img className="header--logo"
         src={bitcoinLogo}
         alt="Bitcoin Logo with link to Bitcoin Whitepaper" />
    </a>
    {start ? <h1 className="header--title">Bitcoin Trivia</h1>
    : form ? <h1 className='header--title'>Submit a Question</h1> : <h1 className="header--title">Welcome to Bitcoin Trivia</h1>}
    <img className="header--image" 
          src={theme === 'dark' ? mascotLight : mascotDark}
          alt="Bitcoin Trivia Mascot"
          aria-label="Change theme to light or dark"
          onClick={toggleTheme} />
    </div>
  )
}

export default Header