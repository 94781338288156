import React, { useContext } from 'react'
import { Context } from '../context'

import './Footer.css'

function Footer() {
  const { toggleTerms, togglePrivacy } = useContext(Context)

  return (
    <div className='footer--container'>
      <div className='footer--wrapper'>
        <p className="footer--anchor"
            onClick={() => toggleTerms(true)}
            aria-label='Terms and Conditions'>Terms</p>
        <p className="footer--pipe">|</p>
        <p className="footer--text">
            Made with 🧡 by 
            <a className="footer--anchor"
               href="https://twitter.com/enjoywithouthey"
               target={"_blank"}
               rel={"noreferrer"}
               aria-label='Twitter Account'> @enjoywithouthey</a>
        </p>
        <p className="footer--pipe">|</p>
        <p className="footer--anchor"
           onClick={() => togglePrivacy(true)}
           aria-label='Privacy Policy'>Privacy</p>
      </div>
    </div>
  )
}

export default Footer