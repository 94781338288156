import React, { useContext } from 'react'
import Header from "./Header"
import Footer from "./Footer"
import Modal from './Modal'
import Form from './Form'
import Terms from './Terms'
import Privacy from './Privacy'

import Countdown from './Countdown'

import './Start.css'
import bitcoinLogo from '../images/bitcoinLogo.png'

import { Context } from "../context"

function Start() {
    const { theme, modal, toggleModal, form, toggleForm, terms, privacy } = useContext(Context)

  return (
    <div className={`${theme}-theme main--background`}>
        {terms && <Terms />}
        {privacy && <Privacy />}
        {form && <Form />}
        {modal && <Modal />}
        <Header />
        <div className='start--container'>
          <div className='start--text'>
            <p>Need to more good Bitcoin vibes in your daily routine? You've come to the right place.</p><br />
            <p>Bitcoin Trivia is a daily dose of fabulous and challenging questions, all related to Bitcoin and Bitcoin only!
              Once a day, three questions are randomly selected from our database. Can you answer them without any help from the interwebs?
              If you get any wrong, you'll have to wait ten minutes or roughly one Bitcoin block to try again. Tick tock next block.</p><br />
              <p>The motivation behind this app is to help grow the adoption of Bitcoin in a fun and entertaining way.
              So if you get stuck on a question, share it on Twitter and get the conversation going.
              Or better yet, if you're enjoying the app, share the questions with your friends & followers.</p><br />
              <p>Are you ready?</p>
          </div>
          <div className='start--buttons'>
            <Countdown />
            <button
                className={`${theme}-theme start--button`}
                onClick={() => toggleModal(true)}
                aria-label="Open btc wallet address information">
                  <div className='start--bitcoin'>
                    <p className="start--bitcoin__text">Make it Happen</p>
                    <img className="start--bitcoin__logo"
                         src={bitcoinLogo}
                         alt="Bitcoin logo on donation button"/>
                    </div>
                    
            </button>
          </div>
          <div className='start--text'>
            <p> Loving Bitcoin Trivia? You want to submit a question don't yah?
              We're open to submissions from the community.
              Click below and give it a go. If you're more dev-savvy and would like to help the project grow or have a general suggestion,
              please visit us on Github.</p>
              <p>Finally, if you're a true legend, support the project by sending some sats.
              Devs have to eat too. Make it happen by smashing the button above.</p><br />
          </div>
            <div className='start--buttons'>
                  <button
                    className={`${theme}-theme start--button`}
                    onClick={() => toggleForm(true)}
                    aria-label="Submit a question to Bitcoin Trivia">
                    Submit a Question 😎
                  </button>
                <a href='https://github.com/enjoywithouthey/bitcointrivia' target='_blank' rel="noopener noreferrer">
                  <button
                    className={`${theme}-theme start--button`}
                    aria-label="Link to Bitcoin Trivia repo on GitHub">
                    Contribute on Github 👩‍💻
                  </button>
                </a>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Start